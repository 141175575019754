import Toast from './toast/toast'
import domready from 'domready'

domready(()=> {
  const flashMessages = document.querySelectorAll('#flash-messages .flash')
  flashMessages.forEach((msg) => {
    if (!Toast[msg.dataset.type]) {
      Hubbado.error(`Unknown flash: ${msg.dataset.type}`)
    } else {
      Toast[msg.dataset.type]({ title: msg.querySelector('.message').innerHTML })
    }
  })
})


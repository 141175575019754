import toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
import './toast.scss'
import { createToastContent } from './utils';

const showToast = (msg, opts = {}) => {
  const toast = toastify({
    className: "toast",
    escapeMarkup: false,
    node: msg,
    ...opts,
  })
  return toast.showToast()
}

window.Hubbado = window.Hubbado || {}
window.Hubbado.toast = {
  success: ({
    title = "",
    message = "",
    options = {}
  }) => showToast(createToastContent(title, message, 'success'), options),
  error: ({
    title = "",
    message = "",
    options = {}
  }) => showToast(createToastContent(title, message, 'error'), options),
  info: ({
    title = "",
    message = "",
    options = {}
  }) => showToast(createToastContent(title, message, 'info'), options),
  warn: ({
    title = "",
    message = "",
    options = {}
  }) => showToast(createToastContent(title, message, 'warn'), options),
}

export default window.Hubbado.toast

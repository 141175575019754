const getIcon = (type) => {
  switch (type) {
    case "success": {
      return "fa-check";
    }
    case "error": {
      return "fa-times"
    }
    case "info": {
      return "fa-info"
    }
    case "warn": {
      return "fa-exclamation-triangle"
    }
    default: {
      return "fa-check";
    }
  }
}

const createToastIcon = (type) => {
  const iconContainer = document.createElement('div');
  iconContainer.classList.add('icon', type);

  const icon = document.createElement("i");
  icon.classList.add("fa", getIcon(type));
  iconContainer.append(icon);

  return iconContainer;
}

const createToastElementText = (className, text) => {
  const element = document.createElement("span");
  element.classList.add(className);
  element.innerHTML= text
  return element;
}

export const createToastContent = (title, message, type) => {
  const container = document.createElement('div');
  container.classList.add('toast-container', type);
  
  const icon = createToastIcon(type);
  container.append(icon)
  
  if (title || message) {
    const details = document.createElement('div');
    details.classList.add("details-container")
    if (title) {
      const titleElement = createToastElementText("title", title);
      details.append(titleElement)
    }
    if (message) {
      const messageElement = createToastElementText("message", message);
      details.append(messageElement);
    }
    container.append(details)
  }
  return container;
}
